import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CardRubleIcon(props) {
  return (
    <SvgIcon viewBox="0 0 36 36" sx={{ width: 36, height: 36 }} {...props}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="15" fill="#4520AB" />
        <path
          d="M16.7667 19.0564V20.2884H19.8547V21.4724H16.7667V23.6004H15.4867V21.4724H14.2227V20.2884H15.4867V19.0564H14.2227V17.8724H15.4867V12.4004H18.5907C19.668 12.4004 20.5053 12.6937 21.1027 13.2804C21.7107 13.8671 22.0147 14.6831 22.0147 15.7284C22.0147 16.7737 21.7107 17.5897 21.1027 18.1764C20.5053 18.7631 19.668 19.0564 18.5907 19.0564H16.7667ZM16.7667 17.8724H18.4627C19.2093 17.8724 19.7747 17.6911 20.1587 17.3284C20.5427 16.9657 20.7347 16.4324 20.7347 15.7284C20.7347 15.0351 20.5427 14.5071 20.1587 14.1444C19.7747 13.7711 19.2093 13.5844 18.4627 13.5844H16.7667V17.8724Z"
          fill="#29EDFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 29C24.0751 29 29 24.0751 29 18C29 11.9249 24.0751 7 18 7C11.9249 7 7 11.9249 7 18C7 24.0751 11.9249 29 18 29ZM18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30Z"
          fill="#29EDFF"
        />
      </svg>
    </SvgIcon>
  );
}
