import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CardFreeIcon(props) {
  return (
    <SvgIcon viewBox="0 0 45 22" sx={{ width: 45, height: 22 }} {...props}>
      <svg
        width="45"
        height="22"
        viewBox="0 0 45 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="45" height="22" rx="8" fill="#29EDFF" />
        <path
          d="M10.9414 7.944V10.044H14.1454V11.388H10.9414V15H9.52538V6.6H15.0214V7.944H10.9414ZM18.8807 11.856H17.7287V15H16.3127V6.6H19.4327C19.9207 6.6 20.3567 6.716 20.7407 6.948C21.1247 7.172 21.4207 7.484 21.6287 7.884C21.8447 8.284 21.9527 8.732 21.9527 9.228C21.9527 9.812 21.8087 10.324 21.5207 10.764C21.2327 11.204 20.8407 11.516 20.3447 11.7L22.0847 15H20.3927L18.8807 11.856ZM17.7287 10.512H19.2287C19.6607 10.512 19.9847 10.396 20.2007 10.164C20.4247 9.932 20.5367 9.62 20.5367 9.228C20.5367 8.836 20.4247 8.524 20.2007 8.292C19.9847 8.06 19.6607 7.944 19.2287 7.944H17.7287V10.512ZM28.788 13.656V15H23.64V6.6H28.668V7.944H25.056V10.044H28.14V11.388H25.056V13.656H28.788ZM35.8633 13.656V15H30.7153V6.6H35.7433V7.944H32.1313V10.044H35.2153V11.388H32.1313V13.656H35.8633Z"
          fill="#4520AB"
        />
      </svg>
    </SvgIcon>
  );
}
