import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CardMessageIcon(props) {
  return (
    <SvgIcon viewBox="0 0 36 36" sx={{ width: 36, height: 36 }} {...props}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="15" fill="#4520AB" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 11H11C9.89543 11 9 11.8954 9 13L9 25.4371L13.4827 23.3682C14.0082 23.1256 14.5801 23 15.1589 23H25C26.1046 23 27 22.1046 27 21V13C27 11.8954 26.1046 11 25 11ZM11 10C9.34315 10 8 11.3431 8 13V25.4371C8 26.1669 8.75644 26.6509 9.41906 26.345L13.9017 24.2761C14.2959 24.0942 14.7248 24 15.1589 24H25C26.6569 24 28 22.6569 28 21V13C28 11.3431 26.6569 10 25 10H11Z"
          fill="#29EDFF"
        />
        <path
          d="M15 17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17C13 16.4477 13.4477 16 14 16C14.5523 16 15 16.4477 15 17Z"
          fill="#29EDFF"
        />
        <path
          d="M19 17C19 17.5523 18.5523 18 18 18C17.4477 18 17 17.5523 17 17C17 16.4477 17.4477 16 18 16C18.5523 16 19 16.4477 19 17Z"
          fill="#29EDFF"
        />
        <path
          d="M23 17C23 17.5523 22.5523 18 22 18C21.4477 18 21 17.5523 21 17C21 16.4477 21.4477 16 22 16C22.5523 16 23 16.4477 23 17Z"
          fill="#29EDFF"
        />
      </svg>
    </SvgIcon>
  );
}
