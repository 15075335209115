import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CardCheckIcon(props) {
  return (
    <SvgIcon viewBox="0 0 36 36" sx={{ width: 36, height: 36 }} {...props}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="15" fill="#4520AB" />
        <path
          d="M8 13.6795C7.72386 13.6795 7.5 13.9033 7.5 14.1795C7.5 14.4556 7.72386 14.6795 8 14.6795V13.6795ZM28 14.6795C28.2761 14.6795 28.5 14.4556 28.5 14.1795C28.5 13.9033 28.2761 13.6795 28 13.6795V14.6795ZM13 10.0769C13 10.3531 13.2239 10.5769 13.5 10.5769C13.7761 10.5769 14 10.3531 14 10.0769H13ZM14 7C14 6.72386 13.7761 6.5 13.5 6.5C13.2239 6.5 13 6.72386 13 7H14ZM22 10.0769C22 10.3531 22.2239 10.5769 22.5 10.5769C22.7761 10.5769 23 10.3531 23 10.0769H22ZM23 7C23 6.72386 22.7761 6.5 22.5 6.5C22.2239 6.5 22 6.72386 22 7H23ZM15.358 19.4715C15.1652 19.2737 14.8487 19.2697 14.6509 19.4625C14.4532 19.6553 14.4492 19.9718 14.642 20.1696L15.358 19.4715ZM17 21.8718L16.642 22.2208L17 22.588L17.358 22.2208L17 21.8718ZM21.358 18.1183C21.5508 17.9206 21.5468 17.604 21.3491 17.4112C21.1513 17.2185 20.8348 17.2225 20.642 17.4202L21.358 18.1183ZM11 9.03846H25V8.03846H11V9.03846ZM27.5 11.6154V23.9231H28.5V11.6154H27.5ZM25 26.5H11V27.5H25V26.5ZM8.5 23.9231V11.6154H7.5V23.9231H8.5ZM11 26.5C9.63115 26.5 8.5 25.3583 8.5 23.9231H7.5C7.5 25.8865 9.05515 27.5 11 27.5V26.5ZM27.5 23.9231C27.5 25.3583 26.3689 26.5 25 26.5V27.5C26.9449 27.5 28.5 25.8865 28.5 23.9231H27.5ZM25 9.03846C26.3689 9.03846 27.5 10.1802 27.5 11.6154H28.5C28.5 9.65191 26.9449 8.03846 25 8.03846V9.03846ZM11 8.03846C9.05515 8.03846 7.5 9.65191 7.5 11.6154H8.5C8.5 10.1802 9.63115 9.03846 11 9.03846V8.03846ZM8 14.6795H28V13.6795H8V14.6795ZM14 10.0769V7H13V10.0769H14ZM23 10.0769V7H22V10.0769H23ZM14.642 20.1696L16.642 22.2208L17.358 21.5227L15.358 19.4715L14.642 20.1696ZM17.358 22.2208L21.358 18.1183L20.642 17.4202L16.642 21.5227L17.358 22.2208Z"
          fill="#29EDFF"
        />
      </svg>
    </SvgIcon>
  );
}
