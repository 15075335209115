import { useStaticQuery, graphql } from 'gatsby';
import { getImageDataMapByFileEdges } from '../../helpers/lib';

export const usePaymentQuery = () => {
  const { allFile } = useStaticQuery(graphql`
    query paymentQuery {
      allFile(filter: { relativeDirectory: { regex: "/payment/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            relativeDirectory
            name
            absolutePath
          }
        }
      }
    }
  `);

  const paymentImgs = getImageDataMapByFileEdges(allFile.edges);
  return { paymentImgs };
};
